<template>
    <el-container>
        <el-header class="custom-header">
            <ul class="index_ul">
                <li class="index_li" @click="currentView = 'home'">首页</li>
                <li class="index_li" @click="currentView = 'alcohol'">酒类</li>
            </ul>
        </el-header>
        <el-main>
            <homeLogin v-show="currentView === 'home'" />
            <alcoholShow v-show="currentView === 'alcohol'" />
        </el-main>
        <div class="el-login-footer">
            <span>湘ICP备18025333号-1 |湘ICP备18025333号</span>
        </div>
    </el-container>
</template>
  
<script>
import homeLogin from './homeLogin.vue'
import alcoholShow from './homeLogin/alcohol.vue'
export default {
    components: {
        homeLogin,
        alcoholShow
    },
    name: "Login",
    data() {
        return {
            currentView: 'home',
        }
    },
    created() { },
    methods: {

    },
};
</script>
  
<style lang="less" scoped>
.custom-header {
    background-color: #b3c0d1;
    color: #333;
    line-height: 20px;
    height: 35px ! important;
}

.el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    margin: 0;
    padding: 0;
}

.el-container {
    width: 100%;
    height: 100%;
}

.index_ul {
    margin: 6px;
    padding: 0;
    list-style: none;
}

.index_li {
    display: inline-block;
    margin-right: 10px;
}

.el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
}</style>
  