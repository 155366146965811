
import { getAction,postAction,deleteAction } from '@/api/common/request'
const baseURL = 'xun/work/xunFoodBase'

const listFoodDataApi =(params)=>getAction(baseURL+"/listData",params);
const listFoodDataAllApi =(params)=>getAction(baseURL+"/listDataAll",params);
const saveOrUpdataFoodBaseApi =(params)=>postAction(baseURL+"/saveOrUpdataBase",params);
const selectFoodByIdApi =(params)=>getAction(baseURL+"/selectById",params);
const deleteFoodByIdApi =(params)=>deleteAction(baseURL+"/deleteById",params);
const overtListApi =(params)=>getAction(baseURL+"/overtList",params);



export{
    listFoodDataApi,
    listFoodDataAllApi,
    saveOrUpdataFoodBaseApi,
    deleteFoodByIdApi,
    selectFoodByIdApi,
    overtListApi
}