<template>
    <div class="login">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
            <h3 class="title">xun综合系统</h3>
            <el-form-item prop="userName">
                <el-input v-model="loginForm.userName" type="text" prefix-icon="el-icon-user-solid" auto-complete="off"
                    placeholder="账号"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="密码"
                    prefix-icon="el-icon-search"></el-input>
            </el-form-item>
            <!-- <el-form-item prop="code" v-if="captchaEnabled">
            <el-input
              v-model="loginForm.code"
              auto-complete="off"
              placeholder="验证码"
              style="width: 63%"
              @keyup.enter.native="loginButton"
            >
            </el-input>
            <div class="login-code">
              <img :src="codeUrl" @click="getCode" class="login-code-img" />
            </div>
          </el-form-item>-->
            <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
            <el-form-item prop="isAuthorize">
                <el-checkbox v-model="loginForm.isAuthorize" :checked="loginForm.isAuthorize === 1 ? true : false"
                    :true-label="1" :false-label="2">我已经阅读并同意用户协议</el-checkbox>
            </el-form-item>
            <el-form-item style="width: 100%">
                <el-button :loading="loading" size="medium" type="primary" style="width: 100%"
                    @click.native.prevent="loginButton">
                    <span v-if="!loading">登 录</span>
                    <span v-else>登 录 中...</span>
                </el-button>
                <div style="float: right">
                    <router-link class="link-type" :to="'/register'">立即注册</router-link>
                </div>
            </el-form-item>
        </el-form>
        <!--  底部  -->
        <div class="el-login-footer">
            <span>湘ICP备18025333号-1 |湘ICP备18025333号</span>
        </div>
    </div>
</template>
  
<script>
import { aes128Encrypt } from "@/utils/ase128";
import { doLogin, register, loginOut, test } from "@/api/user/user";
import { resetRouter } from "@/router/router.js";
export default {
    name: "Login",
    data() {
        var validateAgree = (rule, value, callback) => {
            if (value === 1) {
                callback();
            } else {
                callback(new Error("请勾选同意协议"));
            }
        };
        return {
            codeUrl: "",
            activeIndex2: "1",
            loginForm: {
                userName: "",
                password: "",
                rememberMe: false,
                code: "",
                uuid: "",
                isAuthorize: 2,
            },
            loginRules: {
                userName: [
                    { required: true, trigger: "blur", message: "请输入您的账号" },
                ],
                password: [
                    { required: true, trigger: "blur", message: "请输入您的密码" },
                ],
                isAuthorize: [
                    {
                        required: true,
                        validator: validateAgree,
                        trigger: "blur",
                    },
                ],
                // code: [{ required: true, trigger: "change", message: "请输入验证码" }]
            },
            loading: false,
            // 验证码开关
            captchaEnabled: true,
            // 注册开关
            register: false,
            redirect: undefined,
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true,
        },
    },
    created() { },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        loginButton() {
            var lastIp = sessionStorage.getItem("ip");
            this.$refs["loginForm"].validate((valid) => {
                if (valid) {
                    this.loginForm.ip = lastIp;
                    this.loginForm.password = aes128Encrypt(this.loginForm.password);
                    doLogin(this.loginForm).then((res) => {
                        resetRouter(null);
                        this.$message.success("登录成功");
                        this.$router.push("/home");
                        window.sessionStorage.setItem("token", res.result);
                    });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
  
<style rel="stylesheet/scss" lang="scss">
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url("../assets/bg.jpg");
    background-size: cover;
}

.title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
}

// background: #ffffff;
.login-form {
    border-radius: 6px;
    background-color: rgba(221, 234, 191, 0.5);
    width: 400px;
    padding: 25px 25px 5px 25px;

    .el-input {
        height: 38px;

        input {
            height: 38px;
        }
    }

    .input-icon {
        height: 39px;
        width: 14px;
        margin-left: 2px;
    }
}

.login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
}

.login-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
        cursor: pointer;
        vertical-align: middle;
    }
}

.el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
}

.login-code-img {
    height: 38px;
}

.login_container {
    background: #e0ffff;
    height: 100%;
}
</style>
  