const crypto = require('crypto');
const key = "qGp3K5OXkH191d4r";
const iv = "zkA8frNv3eZ5gEuu";

// 加密
export function aes128Encrypt(data) {
    const cipher = crypto.createCipheriv('aes-128-cbc', key, iv);
    let encrypted = cipher.update(data, 'utf8', 'base64');
    encrypted += cipher.final('base64');
    return encrypted;
}

//解密
export function aes128Decrypt(encrypted) {
    const decipher = crypto.createDecipheriv('aes-128-cbc', key, iv);
    let decrypted = decipher.update(encrypted, 'base64', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
}