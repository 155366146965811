<template>
    <div class="infinite-list-wrapper" v-infinite-scroll="load" infinite-scroll-delay="200">
        <el-row :gutter="20">
            <el-col v-for="item in alcoholList" :key="item.id" :span="6">
                <!-- :offset="1" -->
                <el-card style="height: 400px;margin-top: 10px;">
                    <div slot="header" class="clearfix">
                        <span style="margin-left: 5px">
                            {{ item.name }}--({{ item.categoryName }})
                        </span>
                    </div>
                    <div @dblclick="handleDoubleClick">
                        <el-image  :src="item.mainImages" class="role-info_images">
                        </el-image>
                        <ul class="role-info">
                            <li class="role-info-li">
                                <div class="role-left">
                                    描述信息：{{ item.description }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <p v-if="loading">加载中</p>
        <p v-if="noMore">没有更多的了</p>
    </div>
</template>
<script>
import {
    overtListApi
} from "@/api/life/life/food";
export default {
    data() {
        return {
            loading: false,
            alcoholList: [],
            queryParam: {
                pageSize: 100
            },
            count: 100
        }
    },
    computed: {
        noMore() {
            return this.alcoholList.length >= 100
        },

    },
    created() {
        this.getDataListButton();
    },
    methods: {
        handleDoubleClick() {
            alert("您双击了这个div元素！");
        },
        getDataListButton() {
            overtListApi(this.queryParam).then(res => {
                this.alcoholList = res.result.records
            })
        },
        load(){
            //这里需要改成滚动加载
            console.log('这里需要改成滚动加载')
        }

    }
}
</script>
<style lang="less" scoped>
.role-info_images {
    width: 100%;
    height: 280px;
    object-fit: contain;
}

.role-info {
    list-style: none;
    padding: 0;
}

.role-info-li {
    white-space: normal;
    word-break: break-work;
}

.el-image__inner {
    vertical-align: top;
    height: 280px;
    object-fit: contain;
}

ul.role-info {
    list-style: none;
    padding: 0;
}

/deep/ .el-card__body {
    padding: 20px;
    height: 400px;
}
</style> 